export default {
  // Menú y navegación
  nav: {
    profile: 'Perfil',
    account: 'Cuenta',
    appearance: 'Apariencia',
    customize: 'Personalizar',
    password: 'Cambiar Contraseña',
    integrations: 'Integraciones',
    advanced: 'Avanzado',
  },

  // Formularios y campos
  form: {
    email: 'Correo electrónico',
    password: 'Contraseña',
    submit: 'Enviar',
    cancel: 'Cancelar',
    save: 'Guardar',
    language: 'Idioma',
    select_language: 'Selecciona un idioma',
    language_description: 'Selecciona el idioma de la interfaz.',
  },

  // Mensajes
  message: {
    welcome: 'Bienvenido',
    loading: 'Cargando...',
    error: 'Ha ocurrido un error',
    success: 'Operación exitosa',
    language_updated: 'El idioma de la interfaz ha sido actualizado',
  },

  // Títulos
  title: {
    dashboard: 'Panel de Control',
    settings: 'Configuración',
  },
}
