import { createI18n } from 'vue-i18n'
import es from './locales/es'
import en from './locales/en'

const browserLocale = navigator.language.split('-')[0]
const defaultLocale = ['es', 'en'].includes(browserLocale) ? browserLocale : 'es'

export const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: 'es',
  messages: {
    es,
    en,
  },
})

export function setLocale(locale: string) {
  i18n.global.locale.value = locale
  localStorage.setItem('userLocale', locale)
  document.querySelector('html')?.setAttribute('lang', locale)
}

const savedLocale = localStorage.getItem('userLocale')
if (savedLocale) {
  setLocale(savedLocale)
}
