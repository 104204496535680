import type { AxiosResponse } from 'axios'
import { acceptHMRUpdate, defineStore } from 'pinia'
import AuthService from '@/services/auth.service'
import type { IRegisterUser, IUserLogin, IResendEmail } from '@/types/general'
import type { User } from '@/types/user'

interface IState {
  user: Record<string, unknown> | null
}

const initialState: IState = { user: AuthService.getUser() }

export const useAuthStore = defineStore('auth.store', {
  state: (): IState => ({ ...initialState }),
  getters: {
    isAuthenticated: (state: IState) => {
      const cookieName = '_certificate'
      const cookies = document.cookie.split(';')
      const cookieExists = cookies.some(cookie =>
        cookie.trim().startsWith(`${cookieName}=`),
      )
      if (!cookieExists) {
        return false
      } else {
        return !!state.user
      }
    },
  },
  actions: {
    login(user: IUserLogin) {
      return AuthService.login(user).then(
        (response: AxiosResponse) => {
          this.user = response.data
          return Promise.resolve(response)
        },
        error => {
          return Promise.reject(error)
        },
      )
    },
    resendConfirmation(user: IResendEmail) {
      return AuthService.resendConfirmation(user).then(
        (response: AxiosResponse) => {
          return Promise.resolve(response)
        },
        error => {
          return Promise.reject(error)
        },
      )
    },
    forgetPassword(user: IResendEmail) {
      return AuthService.forgetPassword(user).then(
        (response: AxiosResponse) => {
          return Promise.resolve(response)
        },
        error => {
          return Promise.reject(error)
        },
      )
    },
    logout() {
      AuthService.logout().then(() => {
        this.user = null
      })
    },
    register(user: IRegisterUser) {
      return AuthService.register(user)
    },
    updateUser(userData: Partial<User>) {
      this.user = { ...this.user, ...userData }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
