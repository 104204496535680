export default {
  // Menu and navigation
  nav: {
    profile: 'Profile',
    account: 'Account',
    appearance: 'Appearance',
    customize: 'Customize',
    password: 'Change Password',
    integrations: 'Integrations',
    advanced: 'Advanced',
  },

  // Forms and fields
  form: {
    email: 'Email',
    password: 'Password',
    submit: 'Submit',
    cancel: 'Cancel',
    save: 'Save',
    language: 'Language',
    select_language: 'Select a language',
    language_description: 'Select the interface language.',
  },

  // Messages
  message: {
    welcome: 'Welcome',
    loading: 'Loading...',
    error: 'An error has occurred',
    success: 'Operation successful',
    language_updated: 'The interface language has been updated.',
  },

  // Titles
  title: {
    dashboard: 'Dashboard',
    settings: 'Settings',
  },
}
